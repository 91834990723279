// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.input-wrapper {
  width: 100%;
  height: 2.5rem;
  border: none;
  border-radius: 5px;
  padding: 0 15px;
  box-shadow: 0px 0px 8px #ddd;
  background-color: white;
  display: flex;
  align-items: center;
}

input {
  background-color: transparent;
  border: none;
  height: 100%;
  font-size: 1.25rem;
  width: 100%;
  margin-left: 5px;
}

input:focus {
  outline: none;
}

#search-icon {
  color: royalblue;
}

.btn1 {
  width: 6%;
  padding: 10px;
  border: none;
  background: #303134;
  color: #e8eaed;
  text-decoration: none;
  font-family: "PT Sans", sans-serif;
  font-size: 15px;
  text-align: center;
  border-radius: 6px;
  margin-left: 15px;
}

.btn1:hover {
  background: #47484d;
  border: none;
}

.searchDiv {
  width: 100%;
  display: flex;
}
`, "",{"version":3,"sources":["webpack://./src/components/searchBar/SearchBar.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,cAAc;EACd,YAAY;EACZ,kBAAkB;EAClB,eAAe;EACf,4BAA4B;EAC5B,uBAAuB;EACvB,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,6BAA6B;EAC7B,YAAY;EACZ,YAAY;EACZ,kBAAkB;EAClB,WAAW;EACX,gBAAgB;AAClB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,SAAS;EACT,aAAa;EACb,YAAY;EACZ,mBAAmB;EACnB,cAAc;EACd,qBAAqB;EACrB,kCAAkC;EAClC,eAAe;EACf,kBAAkB;EAClB,kBAAkB;EAClB,iBAAiB;AACnB;;AAEA;EACE,mBAAmB;EACnB,YAAY;AACd;;AAEA;EACE,WAAW;EACX,aAAa;AACf","sourcesContent":[".input-wrapper {\n  width: 100%;\n  height: 2.5rem;\n  border: none;\n  border-radius: 5px;\n  padding: 0 15px;\n  box-shadow: 0px 0px 8px #ddd;\n  background-color: white;\n  display: flex;\n  align-items: center;\n}\n\ninput {\n  background-color: transparent;\n  border: none;\n  height: 100%;\n  font-size: 1.25rem;\n  width: 100%;\n  margin-left: 5px;\n}\n\ninput:focus {\n  outline: none;\n}\n\n#search-icon {\n  color: royalblue;\n}\n\n.btn1 {\n  width: 6%;\n  padding: 10px;\n  border: none;\n  background: #303134;\n  color: #e8eaed;\n  text-decoration: none;\n  font-family: \"PT Sans\", sans-serif;\n  font-size: 15px;\n  text-align: center;\n  border-radius: 6px;\n  margin-left: 15px;\n}\n\n.btn1:hover {\n  background: #47484d;\n  border: none;\n}\n\n.searchDiv {\n  width: 100%;\n  display: flex;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
