// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cardGrid {
  margin-top: 30px;
  display: grid;
  justify-content: center;
  grid-gap: 2.5rem;
  grid-template-columns: repeat(auto-fit, 335px);
}
`, "",{"version":3,"sources":["webpack://./src/components/ImageGrid/ImageGrid.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,aAAa;EACb,uBAAuB;EACvB,gBAAgB;EAChB,8CAA8C;AAChD","sourcesContent":[".cardGrid {\n  margin-top: 30px;\n  display: grid;\n  justify-content: center;\n  grid-gap: 2.5rem;\n  grid-template-columns: repeat(auto-fit, 335px);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
